'use client'

import { Bars3Icon } from '@heroicons/react/24/solid'
import { BuiltWithSub, Button, Popover, Sidebar } from 'components'
import Link from 'next/link'
import React, { useCallback, useState } from 'react'

type Props = {
  items: Array<{
    name: string
    href: string
  }>
}

export const MobileMenu = ({ items }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const onOpenMobileMenu = useCallback(() => {
    setSidebarOpen(true)
  }, [])

  const onCloseMobileMenu = useCallback(() => {
    setSidebarOpen(false)
  }, [])

  const onOpenLink = useCallback(() => {
    setSidebarOpen(false)
  }, [])

  return (
    <>
      {/* Mobile menu button - hidden on desktop */}
      <Button className="btn-ghost lg:hidden" aria-label="Open sidebar" onClick={onOpenMobileMenu}>
        <Bars3Icon className="h-6 w-6" />
      </Button>

      {/* Mobile menu - hidden on desktop */}
      {items.length ? (
        <div className="lg:hidden">
          <Popover open={sidebarOpen} onClose={onCloseMobileMenu}>
            <Sidebar>
              {items.map(item => (
                <Link key={item.href} href={item.href} className="btn btn-ghost justify-start" onClick={onOpenLink}>
                  {item.name}
                </Link>
              ))}

              <div className="flex flex-1 flex-col justify-end">
                <BuiltWithSub />
              </div>
            </Sidebar>
          </Popover>
        </div>
      ) : null}
    </>
  )
}
